<template>
<v-card>
    <v-card-title>
        Video Player Tester
    </v-card-title>
    <v-card-subtitle>
        <v-switch :true-value="true" :false-value="false" v-model='ui.autoplay' label="Autoplay"/>
        <v-switch :true-value="true" :false-value="false" v-model='ui.allowResume' label="Allow Resume"/>
    </v-card-subtitle>

    <v-card-actions>
        <v-list>
            <v-list-item v-for="(video, key) in videos" :key="'video_'+key">
                <v-list-item-content>
                    <v-btn @click="selectVideo(key)">{{key}}</v-btn>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card-actions>

    <v-card-text>
        <v-container>
            <v-row v-if="selectedVideo">
                <v-col>
                    {{selectedVideo}}
                </v-col>
                <v-col>
                    {{videos[selectedVideo]}}
                </v-col>
            </v-row>    
            <v-row>
                <v-col>
                    <VideoPlayer v-model="form.stats" @state_change="playerStateChange" v-if="selectedVideo" :options="{allowResume:ui.allowResume,autoplay:ui.autoplay}" :videoData="selectedVideo"/>
                </v-col>
            </v-row>            
        </v-container>
    </v-card-text>
</v-card>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'
import Hashids from 'hashids'

export default {
    name: 'TestVideo',    
    components: {
        VideoPlayer
    },
    created: function(){
        this.Hasher = new Hashids('Temporary Sald')
    },
    data: function(){
        return {
            ui: {
                allowResume: false,
                autoplay: false
            },
            form: {
                stats: null
            },
            videos: {
                1: {
                    link: {url:'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_30MB.mp4'}
                },
                2: {
                    link: {url:'https://test-videos.co.uk/vids/jellyfish/mp4/h264/1080/Jellyfish_1080_10s_30MB.mp4'}
                },
                3: {
                    link: {url:'https://test-videos.co.uk/vids/sintel/mp4/h264/1080/Sintel_1080_10s_30MB.mp4'}
                }
            },
            Hasher: undefined
        }
    },
    methods: {
        playerStateChange: function(state){
            if(state=='ended'){
                this.selectedVideo = null
                alert('Thanks for watching')
            }
        },
        selectVideo: function(videoID){
            let encoded = this.encode(videoID)
            this.$router.push({
                name: 'Videos',
                params: {
                    video_id: encoded
                }
            })
        },
        encode: function(input){
            let output = null
            if(input && this.Hasher){
                output = this.Hasher.encode(input)
            }
            return output
        },
        decode: function(input){
            let output = null
            if(input && this.Hasher){
                output = this.Hasher.decode(input)
            }
            return output
        }
    },
    computed: {
        selectedVideo: function(){
            let id = this.decode(this.$route.params.video_id)
            if(id){
                return this.videos[id] ? this.videos[id] : null
            }
            return null

        }
    }
}
</script>

<style>

</style>